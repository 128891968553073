
.Support {
  display: flex;
  align-items: center;
  width: 100%;
  height: 72px;
  padding: 0 13px 0 20px;
  border-radius: 12px;
  border: 1px solid rgba(1,3,12,0.1);
}

.ImageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  min-width: 32px;
  height: 41px;
  margin-right: 16px;
}

.Text {
  font-weight: normal;
  font-size: 18px;
  line-height: 1.5;

  @media screen and (max-width: 768px) {
    font-size: 14px;
    line-height: 22px;
  }
}