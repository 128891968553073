
.Ticket {
  margin-bottom: 24px;
}

.Image {
  margin-bottom: 20px;
  cursor: pointer;
  transition: 0.3s;

  img {
    border-radius: 12px;
    overflow: hidden;
  }

  &:hover {
    transform: scale(1.03);
  }
}

.Name {
  margin-bottom: 12px;
  font-size: 18px;
  line-height: 1.5;
}

.Row {
  display: flex;
  align-items: flex-end;
  margin-bottom: 22px;
}

.Price {
  margin-right: 12px;
  font-size: 20px;
  line-height: 1.5;
}

.Dates {
  color: rgba(1,3,12,0.56);
  font-size: 16px;
  line-height: 1.5;
}

.SelectTime {
  cursor: pointer;
  font-weight: 700;
  font-size: 18px;
  line-height: 1.5;
  transition: 0.3s;

  &:hover {
    opacity: 0.6;
  }
}