.Block {
	margin-bottom: 8px;
	padding: 16px 24px;
	border: 1px solid rgba(1, 3, 12, 0.06);

	@media screen and (max-width: 1200px) {
		padding: 16px;
	}
}

.Basket {
	&__Delivery {
		display: flex;
		width: 100%;
		justify-content: space-between;
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		border-bottom: 1px solid rgba(1, 3, 12, 0.06);
		margin-bottom: 17px;
		padding-bottom: 10px;

		&_Title {
			color: #01030C;
		}

		&_Price {
			color: #01030C;
			opacity: 0.56;
		}
	}

	&__Title {
		font-weight: 600;
		font-size: 20px;
		line-height: 28px;

		@media screen and (max-width: 1200px) {
			font-size: 18px;
			line-height: 26px;
		}
	}

	&__TopLine {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 12px;
	}

	&__Row,
	&__Values {
		display: flex;
		align-items: center;
	}

	&__Row,
	&__Values {
		justify-content: space-between;
	}

	&__Values {
		width: calc(50% - 4rem);

		@media screen and (max-width: 576px) {
			width: calc(50% - 2rem);
		}
	}

	&__Items {
		margin-bottom: 24px;
	}

	&__Item {
		padding: 12px 0;
		border-bottom: 1px solid rgba(1, 3, 12, 0.06);
	}

	&__Category {
		font-size: 12px;
		line-height: 20px;
		color: rgba(1, 3, 12, 0.56);
	}

	&__Col {
		max-width: 100%;
	}

	&__Name {
		max-width: 50%;
		font-size: 16px;
		line-height: 1.5;

		@media screen and (max-width: 576px) {
			font-size: 14px;
		}
	}

	&__Count {
		margin-right: 20px;
	}

	&__Count,
	&__Price {
		color: rgba(1, 3, 12, 0.56);
		font-size: 14px;
		line-height: 24px;
	}

	&__Price {
		&Free {
			color: rgba(1, 3, 12, 0.56);
			font-weight: 600;
			font-size: 16px;
			line-height: 24px;
		}
	}

	&__Remove {
		margin-left: 32px;
		height: 24px;
	}

	&__Total {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		margin-bottom: 24px;

		&Text {
			color: rgba(1, 3, 12, 0.64);
			font-size: 16px;
			line-height: 24px;

			@media screen and (max-width: 576px) {
				margin-bottom: 5px;
			}
		}

		&Price {
			font-weight: 600;
			font-size: 24px;
			line-height: 1.1;
		}

		&OldPrice {
			margin-right: 12px;
			color: rgba(1, 3, 12, 0.64);
			text-decoration-line: line-through;
			font-size: 16px;
			line-height: 1;
		}

		@media screen and (max-width: 576px) {
			flex-direction: column;
		}
	}
}

.PromoCodeInfo {
	margin-top: 12px;
	opacity: 0.64;
	font-size: 12px;
	line-height: 16px;
}
