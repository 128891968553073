@mixin hover-nav-list {
	color: #cf086e;
	opacity: 1;
	transition: 0.3s;
}

.Header {
	position: sticky;
	top: 0;
	display: flex;
	justify-content: center;
	background: #fff;
	z-index: 100;
	transition: 0.3s;

	&_black {
		position: fixed;
		width: 100%;
		background: rgba(1, 3, 12, 0.32);
		backdrop-filter: blur(64px);
		transition: backdrop-filter 0.1s;

		.Link,
		.Tickets span {
			color: #fff;
		}

		.IconButton {
			fill: #fff;
		}

		input {
			background: transparent;
		}
	}
	&_black_open {
		
		.Main {
			border-bottom: 1px solid rgba(1, 3, 12, 0.06);
		}
	}
	&_fixed {
		position: fixed !important;
		width: 100%;
	}
}

.Main,
.Nav,
.Row {
	display: flex;
	align-items: center;
}

.Main {
	justify-content: space-between;
}

.Row {
	&:last-child {
		@media screen and (max-width: 1200px) {
			width: 70%;
			justify-content: flex-end;
		}
	}

	@media screen and (max-width: 768px) {
		width: auto !important;
	}
}

.Wrapper {
	width: 1200px;
	max-width: 100%;
	padding: 0 24px;

	@media screen and (max-width: 1200px) {
		width: 768px;
		padding: 0 16px;
	}
}

.Search {
	border-bottom: 1px solid rgba(1, 3, 12, 0.06);
}

.Main {
	height: 89px;

	@media screen and (max-width: 768px) {
		height: 68px;
	}
}

.Nav {
	width: calc(100% - 176px - 48px);
	justify-content: space-between;

	@media screen and (max-width: 768px) {
		justify-content: flex-end;
	}
}

.Burger {
	display: flex;
	width: 20px;
	margin-right: 12px;
	cursor: pointer;

	@media screen and (max-width: 768px) {
		margin-right: 24px;
	}
}

.Logo {
	display: flex;
	width: 176px;
	margin-right: 35px;
	cursor: pointer;
	transition: 0.3s;

	&:hover {
		opacity: 0.6;
	}
}

.Link {
	margin-right: 28px;
	font-family: SourceSansPro !important;
	transition: 0.3s;
	cursor: pointer;

	&.selected:not(&.All) {
		opacity: 0.5;
		&:hover {
			@include hover-nav-list;
		}

		&.BlackTheme {
			transition: 0.3s;

			&:hover {
				color: #fff;
				opacity: 1;
			}
		}
	}

	&.BlackTheme {
		transition: 0.3s;

		&:hover {
			color: #fff;
			opacity: 0.6;
		}
	}

	&.All {
		&.selected {
			& ~ .Link {
				opacity: 0.5;
				transition: 0.3s;
				&:hover {
					@include hover-nav-list;
				}
			}
		}
	}

	&:hover {
		@include hover-nav-list;
	}

	&:last-child {
		margin-right: 0;
	}
}

.IconButton {
	margin-right: 28px;

	&:last-child {
		margin-right: 0;
		padding-right: 0;
	}

	&_selected {
		fill: red !important;
	}
}

.Tickets {
	display: flex;
	align-items: center;
	margin-right: 20px;
	cursor: pointer;
	user-select: none;

	&.DefaultTheme {
		&:hover {
			opacity: 1;
			span {
				@include hover-nav-list;
				transition: none;
			}
		}
	}

	@media screen and (max-width: 1200px) {
		margin-right: auto;
	}
}

.Basket {
	position: relative;
	margin-right: 28px;

	@media screen and (max-width: 768px) {
		margin-right: 0;
	}
}

.Counter {
	position: absolute;
	bottom: 0;
	right: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 15px;
	height: 15px;
	border-radius: 50%;
	background: #cf086e;
	color: #fff;
	font-weight: 700;
	font-size: 10px;
	line-height: 22px;
}

.Arrow {
	display: flex;
	align-items: center;
	justify-content: center;
	transform: rotate(180deg);
	margin-left: 8px;

	&_show {
		transform: rotate(0deg);
	}
}

.MuseumMenu {
	position: absolute;
	top: 80px;
	background: #fff;
	box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.16), 0px 4px 8px rgba(0, 0, 0, 0.03);
	border-radius: 12px;
	z-index: 100000;
	overflow: hidden;

	&__Link {
		display: flex;
		align-items: center;
		padding: 12px 20px;
		border-bottom: 1px solid rgba(0, 0, 0, 0.06);

		&:last-child {
			border-bottom: none;
		}
	}
}