.react-select {
  &-container {
    padding-bottom: 12px !important;
  }
  &__control {
    margin-bottom: 16px !important;
    border-top: 0 none !important;
    border-left: 0 none !important;
    border-right: 0 none !important;
    border-radius: 0 !important;
    &--is-focused {
      border: 0 none !important;
      box-shadow: none !important;
    }
  }

  &__value-container {
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 24px;
    color: #01030C !important;
    padding-left: 0 !important;
    padding-bottom: 12px !important;
  }

  &__indicator-separator {
    display: none !important;
  }
}