
.Loading {
  width: 20px;
  height: 20px;
  margin-left: -5px;
  margin-top: -5px;
  background: transparent;
}

.Slider {
  width: 100%;
  height: 480px;

  &__Dots {
    position: absolute;
    display: flex;
    align-items: center;
    width: 100%;
    bottom: 75px;
    background: red !important;

    button:before {
      opacity: 0.4 !important;
      color: #fff !important;
    }
  }

  .slick-active button:before {
    opacity: 1 !important;
    background: url('../../assets/slider/subtractedCircle.svg') no-repeat center / cover;
  }

  &_compact {
    height: 88px;
    overflow: hidden;
  }
}

.Slide {
  position: relative;
  width: 100%;
  height: 480px;

  &__Background {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    &_contain {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;
      }
    }
  }

  &__Content {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    &_withShadow {
      background: rgba(0,0,0, 0.35);
    }
  }

  &__Title {
    margin-bottom: 32px;
    padding: 0 20px;
    min-height: 64px;
    color: #fff;
    font-family: 'CormorantGaramond' !important;
    font-weight: 700;
    font-size: 64px;
    line-height: 56px;
    text-align: center;

    @media screen and (max-width: 1200px) {
      font-size: 56px;
      line-height: 48px;
    }

    @media screen and (max-width: 768px) {
      font-size: 40px;
      line-height: 48px;
    }
  }

  &__Button {
    width: 140px;
  }
}