
.ToUpArrow {
  position: fixed;
  right: 32px;
  bottom: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  background: #FCF0F6;
  border-radius: 50%;
  cursor: pointer;
  transition: 0.3s;
  z-index: 100000;

  &:hover {
    opacity: 0.6;
  }
  
  @media screen and (max-width: 576px) {
    bottom: 100px;
    right: 16px;
  }
}

.Arrow {
  display: flex;
  align-items: center;
  justify-content: center;
}