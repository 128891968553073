.Content {
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(6, 120px);
  gap: 24px;
  width: 100%;
  height: max-content;
  padding: 25px 0 25px 25px;

  @media screen and (max-width: 1200px) {
    grid-template-columns: repeat(3, 120px);
    padding: 46px 53px;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    padding: 20px 0 20px 20px;
  }
}

.CategoryCard {
  width: 100%;
}
