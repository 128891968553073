
.SmallContainer {
  width: 384px;
  max-width: 100%;
}

.MediumContainer {

}

.LargeContainer {

}

.CenteredContent {
  margin: 0 auto;
}