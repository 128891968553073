.Wrapper {
	width: 1200px;
	max-width: 100%;
	margin: 0 auto;
}

.Row {
	margin-bottom: 64px;
}

.Button {
	margin-top: 40px;
}

.Items {
	gap: 0;
	grid-gap: 0;

	@media screen and (max-width: 576px) {
		grid-template-columns: 1fr !important;
	}
}