.Block {
  margin-bottom: 8px;
  padding: 16px 24px;
  border: 1px solid rgba(1,3,12,0.06);

  @media screen and (max-width: 1200px) {
    padding: 16px;
  }
}

.HaveAcc {
  display: flex;
  justify-content: space-between;
  margin-right: -6px;

  &__Arrow {
    position: inherit;
    display: inline-flex;
    margin-left: 2px;

    path {
      fill: #CF086E !important;
    }
  }

  &__Link {
    display: flex;
    align-items: center;
    height: 20px;
  }
}
