
.Title {
  margin-bottom: 12px;
  text-align: center;
  font-family: Cormorant Garamond !important;
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;

  @media screen and (max-width: 768px) {
    font-size: 36px;
    line-height: 44px;
  }
}

.SubTitle {
  margin-bottom: 20px;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  color: rgba(1,3,12,0.56);

  @media screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 24px;
  }
}

.Wrapper {
  width: 384px;
  margin: 0 auto;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.Form {
  margin-top: 50px;
}

.Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.Status {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Message {
  margin-top: 15px;
  text-align: center;
  color: #CF086E;
  font-size: 20px;

  &_success {
    color: #008a00;
  }

  &_error {
    color: #d53e3e;
  }

  &_action {
    margin-top: 45px;
    font-size: 18px;
    color: #01030C !important;
    cursor: pointer;
    transition: 0.3s;
    border-bottom: 1px solid #01030C;

    &:hover {
      opacity: 0.6;
    }
  }
}

.Timer {
  margin-top: 45px;
  font-size: 14px;
  line-height: 1.5;
  opacity: 0.8;
}