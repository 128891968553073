@mixin mark {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 20px;
	background: #cf086e;
	border-radius: 10px;
	color: #fff;
	font-weight: 600;
	font-size: 12px;
	line-height: 26px;
}

.Preview {
	position: relative;
	width: 100%;
	height: 360px;
	display: flex;
	justify-content: center;
	align-items: center;
	background: rgba(1, 3, 12, 0.04);
	margin-bottom: 20px;

	@media screen and (max-width: 768px) {
		height: 184px;
		margin-bottom: 16px;
	}

	@media screen and (max-width: 576px) {
		max-width: 100%;
	}
}

.Image {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	transition: 0.1s;
	opacity: 1;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: 50% 50%;

		@media screen and (max-width: 991px) {
			object-fit: contain;
		}
	}

	&_fade {
		opacity: 0;
	}

	&_show {
		opacity: 1;
	}
}

.Liked {
	position: absolute;
	top: 16px;
	right: 16px;
	width: 24px;
	height: 36px;
	cursor: pointer;

	&:hover {
		path {
			fill: #cf086e;
		}
	}
}

.Name {
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	height: 54px;
	margin-bottom: 12px;
	font-size: 18px;
	line-height: 1.5;

	@media screen and (max-width: 768px) {
		margin-bottom: 8px;
	}

	@media screen and (max-width: 576px) {
		height: auto;
		margin-bottom: 16px;
	}
}

.Price {
	margin-bottom: 12px;

	@media screen and (max-width: 768px) {
		margin-bottom: 8px;
	}
}

.Stickers {
	display: flex;
	flex-wrap: wrap;
}

.Container {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	grid-gap: 8px;
	line-height: 1.5;

	& > span:first-of-type {
		margin-right: 8px;
	}

	@media screen and (max-width: 768px) {
		margin-bottom: 8px;
	}
}

.CurrentPrice {
	margin-right: 8px;

	&_unknown {
		font-size: 16px;
		color: rgba(1,3,12,0.48);
	}
}

.OldPrice {
	margin-right: 8px;
	color: rgba(1, 3, 12, 0.32);
	text-decoration-line: line-through;
}

.CurrentPrice, .OldPrice {
	font-size: 20px;
}

.Discount {
	width: 43px;
	margin-right: 8px;
	@include mark;

	@media screen and (max-width: 768px) {
		margin-bottom: 5px;
	}
}

.ProductCard {
	margin-bottom: 48px;
	padding-right: 20px;

	@media screen and (max-width: 768px) {
		margin-bottom: 28px;
	}

	@media screen and (max-width: 576px) {
		max-width: 100%;
		padding-right: 0;
	}
}

.Button {
	padding: 0;
	border: none;
	background: none;
	font-weight: 700;
	font-size: 18px;
	line-height: 24px;

	&:disabled {
		background: transparent;
		cursor: auto;
		opacity: 1;
	}
}

.Link {
	flex-direction: column;
	align-items: flex-start;
}

.Last {
	width: 80px;
	margin-right: 8px;
	@include mark;
}

.Wrapper {
	// padding: 0 15px 0 5px;
}
