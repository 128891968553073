.Wrap {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  &.NotAvailable {
    opacity: 0.5 !important;
  }

  &_one {
    flex-direction: column;
    align-items: flex-start;
    margin: 30px 0;
  }
}

.Counter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 85px;
  padding: 5px 0;
  border: 1px solid rgba(1, 3, 12, 0.12);
}

.Button {
  display: flex;
  justify-content: center;
  cursor: pointer;
  padding: 0 8px;
  transition: 0.3s;
  width: 31px;
  height: 15px;

  &:hover {
    opacity: 0.3;
  }

  &_disabled {
    opacity: 0.2;
    cursor: default;
  }
}

.Value {
  font-size: 14px;
  line-height: 22px;

  &.NotAvailable {
    opacity: 0.5 !important;
  }
}

.Price {
  display: flex;
  align-items: center;
  width: 220px;
  margin-right: 5px;
  font-size: 20px;
  line-height: 28px;
  white-space: nowrap;

  &.NotAvailable {
    opacity: 0.5 !important;
  }
}

.Remainder {
  text-align: center;
  color: rgba(1, 3, 12, 0.56);
  font-size: 12px;
  line-height: 20px;
  height: 0;

  &_row {
    margin-left: 10px;
  }

  &_action {
    color: #cf086e;
    font-weight: bold;
    cursor: pointer;
    transition: 0.3s;
    opacity: 1 !important;

    &:hover {
      opacity: 0.5 !important;
    }
  }
}

.OldPrice {
  margin-right: 10px;
  color: rgba(1, 3, 12, 0.48);
  text-decoration-line: line-through;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
}