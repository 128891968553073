
.Action {
  display: flex;
  padding: 5px;
  background: transparent;
  opacity: 1;
  transition: 0.3s;

  &_black {
    path {
      fill: #01030C !important;
    }

    &:hover {
      path {
        fill: #CF086E !important;
      }
    }

    &:focus {
      path {
        fill: #AB0C5E !important;
      }
    }
  }

  &_white {
    path {
      fill: #fff !important;
    }
  }

  &_pink {
    path {
      fill: #CF086E !important;
    }
  }
}