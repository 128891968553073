
.Promo {
}

.ErrorMessage {
  margin-top: 12px;
  font-size: 12px;
  line-height: 16px;
  color: #D53E3E;
}

.Actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  border: 1px solid rgba(1, 3, 12, 0.12);

  input {
    border: none !important;
    background: transparent !important;
    outline: none !important;
    color: rgba(1, 3, 12, 0.56);
    font-size: 16px;
    line-height: 1;
  }

  &_error {
    border-color: #EB5757;
  }

  @media screen and (max-width: 768px) {
    padding: 12px;

    input {
      width: 65%;
    }
  }
}

.Action {
  cursor: pointer;
  color: #CF086E;
  font-weight: 600;
  font-size: 16px;

  &:hover, &_disabled {
    opacity: 0.6;
    transition: 0.3s;
  }
}

.Loader {
  width: 20px !important;
  height: 100% !important;

  * {
    color: #CF086E;
  }
}

.AcceptedCode {
  margin-top: 10px;
  font-weight: 700;
  font-size: 14px;
  line-height: 1.5;
  color: #CF086E;
}

.CloseIcon {
  cursor: pointer;
}