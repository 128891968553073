.Link {
	display: inline;
	cursor: pointer;
	line-height: 1.5;
	transition: 0.3s;

	&:hover {
		opacity: 0.6;
	}

	&_lighting {
		color: #cf086e;

		* {
			color: #cf086e;
		}
	}

	a {
		font-size: inherit;
	}
}

.Disabled {
	cursor: not-allowed;
	background: #ebebec !important;
	color: #96979b !important;

	span {
		color: #96979b !important;
	}

	&:hover {
		opacity: 1;
		color: #96979b;
	}
}
