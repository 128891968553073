.HaveQuestions {
  margin-top: -1px;
  padding: 20px 25px 25px;
  border: 1px solid rgba(1,3,12,0.06);
}

.Title {
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
}

.SubTitle {
  margin-bottom: 20px;
  color: rgba(1,3,12,0.56);
  font-size: 14px;
  line-height: 22px;
}

.Phone {
  display: block;
  margin-bottom: 12px;
  font-size: 16px;
  line-height: 24px;
}

.Email {
  display: block;
  color: #CF086E;
}