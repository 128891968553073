
.OrderRejected {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 63px 10px;
}

.Circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 120px;
  margin-bottom: 32px;
  background: rgba(235, 87, 87, 0.08);
  border-radius: 50%;

  svg {
    width: 40px;
    height: 28px;
  }
}

.Title, .SubTitle, {
  width: 100%;
  text-align: center;
}

.Title {
  margin-bottom: 12px;
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;
}

.SubTitle {
  margin-bottom: 48px;
  color: rgba(1,3,12,0.56);
  font-size: 16px;
  line-height: 24px;
}
