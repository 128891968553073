
.Tabs {
  position: relative;
}

.Top {
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }
}

.Title {
  margin-bottom: 32px;
  font-family: Cormorant Garamond !important;
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;

  @media screen and (max-width: 1200px) {
    font-size: 36px;
    line-height: 44px;
  }
}

.Nav {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  text-align: center;

  &_withTitle {
    display: flex;
    margin: 20px 0 0;
    width: auto;
    grid-template-columns: none;

    .Tab {
      padding: 0 0 20px;
      margin-right: 20px;

      &:last-child {
        margin-right: 0;
      }
    }

    @media screen and (max-width: 1200px) {
      margin: 0;
      flex-wrap: nowrap;
      overflow-x: auto;
    }

    @media screen and (max-width: 576px) {
      display: grid;
      grid-template-columns: repeat(100, max-content);
      overflow: auto;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}

.Tab {
  padding: 16px 14px;
  color: rgba(1,3,12,0.56);
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;

  &_selected {
    font-weight: 700;
    color: #01030C;
    border-bottom: 2px solid #CF086E;
  }
}

.Divider {
  position: absolute;
  width: 100%;
}

.Content {
  padding: 48px 0;

  @media screen and (max-width: 576px) {
    padding: 24px 0;
  }
}
