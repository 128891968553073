
.Name {
  margin-bottom: 24px;
  font-family: 'CormorantGaramond' !important;
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;

  @media screen and (max-width: 576px) {
    margin-bottom: 12px;
    font-size: 32px;
    line-height: 56px;
  }
}

.Row {
  margin-bottom: 24px;

  &__Content {
    display: flex;
    padding: 16px 0;

    @media screen and (max-width: 576px) {
      padding: 12px 0;
    }
  }

  &__Date {
    margin-left: 20px;
    font-size: 20px;
    line-height: 28px;

    @media screen and (max-width: 576px) {
      font-size: 16px;
      line-height: 24px;
    }
  }
}

.Text {
  font-size: 20px;
  line-height: 28px;

  @media screen and (max-width: 576px) {
    font-size: 16px;
    line-height: 24px;
  }
}

.About {
  margin-bottom: 32px;
  font-size: 16px;
  line-height: 24px;

  @media screen and (max-width: 576px) {
    margin-bottom: 24px;
  }
}

.Preview {
  margin-bottom: 48px;

  img {
    width: 100%;
    height: auto;
    border-radius: 12px;
  }

  @media screen and (max-width: 576px) {
    margin-bottom: 29px;
  }
}

.Calendar {
  margin-bottom: 48px;

  @media screen and (max-width: 576px) {
    margin-bottom: 40px;
  }
}

.TicketsSelector {
  margin-bottom: 80px;
}

.BottomInfo {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;

  @media screen and (max-width: 576px) {
    flex-direction: column;
  }
}

.Contacts, .ContactWithSupport {
  width: 49%;

  @media screen and (max-width: 576px) {
    width: 100%;
  }
}

.Contacts {
  @media screen and (max-width: 576px) {
    margin-bottom: 24px;
  }
}

.ContactWithSupport {
  @media screen and (max-width: 576px) {
    margin-top: 16px;
  }
}