
.Benefits {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  margin-bottom: 97px;

  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }

  @media screen and (max-width: 768px) {
    margin-bottom: 24px;
  }
}

.Benefit {
  display: flex;
  flex-direction: column;
  width: calc(100% / 3 - 16px);
  min-height: 258px;
  padding: 32px 23px 41px;
  border: 1px solid rgba(1, 3, 12, 0.08);
  border-radius: 12px;

  @media screen and (max-width: 1200px) {
    width: 100%;
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.ImageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 77px;
  margin-bottom: 24px;
}

.Title {
  margin-bottom: 8px;
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.5;
}

.Text {
  margin-bottom: 18px;
  text-align: center;
  font-size: 14px;
  line-height: 1.5;
  opacity: 0.8;
}

.ButtonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: auto;
}