
.Banner {
  display: flex;
  padding: 33px 0 80px;
  width: 100%;
  min-height: calc(100vh - 89px);
  background: #F5F5F5;
  opacity: 0;
  margin-bottom: -1px;

  &_show {
    opacity: 1;
  }

  @media screen and (max-width: 1200px) {
    padding: 20px 16px 16px;
  }

  @media screen and (max-width: 768px) {
    min-height: calc(100vh - 68px);
  }
}

.Wrapper {
  position: relative;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 96px;
  background: #fff;
  border-radius: 12px;

  @media screen and (max-width: 576px) {
    padding-bottom: 30px;
  }
}

.Close {
  top: 111px;
  width: 20px;
  height: 20px;
  margin: 28px 28px 28px auto;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    opacity: 0.6;
  }

  img {
    width: 100%;
    height: 100%;
  }

  @media screen and (max-width: 576px) {
    margin: 16px 16px 16px auto;
  }
}

.Content {
  padding: 0 300px;
  width: 100%;
  margin: 0 auto 0;

  @media screen and (max-width: 1200px) {
    padding: 0 100px;
  }

  @media screen and (max-width: 768px) {
    padding: 0 30px;
  }

  @media screen and (max-width: 576px) {
    padding: 0 16px;
  }
}
