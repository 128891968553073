
.Page {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 1440px;
  max-width: 100%;
  padding: 0 120px;
  //min-height: calc(100vh - 89px);
  margin: 0 auto;

  &_hide {
    height: 0 !important;
    overflow: hidden !important;
    min-height: 0 !important;
    max-height: 0 !important;
  }

  @media screen and (max-width: 1200px) {
    width: 768px;
    padding: 0 16px;
  }

  @media screen and (max-width: 768px) {
    min-height: calc(100vh - 68px);
  }
}

.Content {
  padding: 48px 0 104px;

  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 24px 0 32px;
  }
}

.Title {
  margin-bottom: 32px;
  font-family: Cormorant Garamond !important;
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;

  @media screen and (max-width: 768px) {
    font-size: 32px;
    line-height: 1.3;
    margin-bottom: 20px;
  }
}