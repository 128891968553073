

.Sticker {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  margin-left: 8px;
  background: #CF086E;
  border-radius: 50%;

  &_mobile {
    margin-left: 0;
  }

  &__Content {
    color: #fff;
    font-weight: 900;
    font-size: 12px;
    line-height: 24px;
  }
}
