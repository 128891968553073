.EmptyContents {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;

  &__cirle {
    border: 1px solid #E5E5E5;
    width: 160px;
    height: 160px;
    border-radius: 50%;
    position: relative;

    svg {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
    }
  }

  &__text {
    max-width: 384px;
    width: 100%;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #01030C;
    padding: 24px 0 32px 0;
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    padding: 0 48px;
    background: rgba(207,8,110,0.06);
    border-radius: 100px;
    opacity: 1 !important;
    transition: 0.3s;
    white-space: nowrap;
    background: #CF086E;
    color: #ffffff;
    font-size: 18px;
    line-height: 28px;

    &:hover {
      background: #CF086E;
    }

    &:focus {
      background: #AB0C5E;
    }
  }
}
