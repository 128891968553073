
.ProductsHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 16px 0;
  min-height: 48px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
  }
}

.Actions, .Filters, .Filters__Icon, .SortBy {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.Filters, .SortBy {
  @media screen and (max-width: 768px) {
    margin-bottom: 10px;
  }
}

.Actions {
  @media screen and (max-width: 1200px) {
    margin: 10px 0;
  }
}

.Search {
  width: calc(63.6% - 20px);
  margin-right: 20px;
  height: 48px;

  @media screen and (max-width: 1200px) {
    width: 100%;
  }

  &Container {
    position: relative;
    display: flex;
    align-items: center;

    &CopyInput {
      position: absolute;
      display: flex;
      align-items: center;
      width: 100%;
      height: 48px;
      padding: 12px 16px;
      top: 0;
      font-size: 16px;
      pointer-events: none;
      line-height: 1.1876em;
      letter-spacing: 0.00938em;
      color: transparent;

      & svg {
        cursor: pointer;
        pointer-events: all;
        width: 24px;
        height: 24px;
        margin-left: 12px;
      }
    }
  }
}

.Found {
  width: max-content;
  margin-right: 20px;
}

.Search {
  position: relative;

  &__input {
    width: 100%;
    height: 48px;
    font-size: 16px;
    line-height: 24px;
    border: 1px solid rgba(1,3,12,0.08)!important;
    outline: none;
    padding-left: 16px!important;
    padding-top: 0!important;

    & div {
      font-family: SourceSansPro, sans-serif!important;

      &:before {
        content: none!important;
      }

      &:after {
        content: none!important;
      }
    }

    &:focus {
      border: 1px solid rgba(1,3,12,0.3);
    }
  }

  &__Icon {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
  }
}

.Found {
  color: rgba(1,3,12,0.48);
  font-family: SourceSansPro !important;
  font-size: 16px;
  line-height: 24px;
}

.Filters {
  margin-right: 32px;
  cursor: pointer;
  transition: 0.3s;

  &__Icon {
    width: 20px;
    height: 24px;
    margin-right: 8px;
  }

  &__Text {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }

  &:hover {
    opacity: 0.6;
  }
}

.SortBy {
  position: relative;

  &__Selector {
    display: flex;
    align-items: center;
    color: #CF086E;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      opacity: 0.6;
    }

    path {
      fill: #CF086E;
    }
  }

  &__Text {
    margin-right: 5px;
    font-size: 16px;
    line-height: 24px;
    color: inherit;
  }

  &__DropDown {
    position: absolute;
    right: 0;
    top: 25px;
    padding: 10px 20px;
    background: #fff;
    z-index: 1;
    min-width: 210px;

    &Item {
      margin-bottom: 10px;
      cursor: pointer;
      text-align: right;
      color: #CF086E;
      transition: 0.3s;

      &:hover {
        opacity: 0.6;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__Icon {
    &_reverse {
      transform: rotate(180deg);
    }
  }
}

.Actions {
  [data-tippy-root] {
    transform: none !important;
  }
}