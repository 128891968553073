
.Stat {
  width: 160px;
  margin-right: 24px;
  margin-bottom: 20px;
}

.Type {
  color: rgba(1,3,12,0.56);
  font-size: 12px;
  line-height: 20px;
}

.Value {
  font-size: 16px;
  line-height: 1.5;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  white-space: break-spaces;

  &_full {
    display: flex;
  }
}

.Action {
  margin-top: 3px;
  margin-bottom: 10px;
  font-size: 14px;
  color: rgb(207, 8, 110);
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    opacity: 0.7;
  }
}