.Loader {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 2px solid #CF086E;
  border-right-color: #eae4ea;
  animation: loader 1s linear infinite;
  margin: 0 auto;
}

@keyframes loader
{
  0%
  {
    transform: rotate(0deg);
  }
  100%
  {
    transform: rotate(360deg);
  }
}
