.DeliveryType {
  width: 100%;

  &__Content {
    padding-top: 22px;
  }

  &__Map {
    width: 100%;
    height: 325px;
    margin-bottom: 32px;
    background: #CF086E;
  }

  &__Item {
    margin-top: 16px;
  }

  &__Label {
    color: rgba(1,3,12,0.56);
    font-size: 12px;
    line-height: 20px;
  }

  &__Value {
    font-size: 16px;
    line-height: 24px;
  }

  &__Radio {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    &Content {
      margin-left: 4px;
    }

    &Title {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
    }

    &Desc {
      color: rgba(1,3,12,0.64);
      font-size: 12px;
      line-height: 16px;
    }
  }
}

.Title {
  margin: 24px 0;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
}

.Link {
  display: inline-block;
  color: #CF086E;
}

.DeliveryAddress {
  padding-bottom: 20px;
}
