.Search {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	height: 56px;

	&_black {
		.Input {
			input {
				color: #fff !important;
				&::placeholder {
					color: #fff !important;
				}
			}
		}

		.Button {
			&:disabled {
				background: #cf086e;
				color: #fff;
				opacity: 0.8 !important;
			}
		}
	}
}

.Input {
	width: calc(100% - 150px);
	height: 28px;
	font-size: 18px;
	line-height: 28px;
	border: none;
	outline: none;
}

.Button {
	width: 110px;
	height: 36px;
	margin-right: 0;
}

.SearchMobile {
	margin: 20px auto 0;
	position: relative;
	width: 100%;

	&__Icon {
		padding: 0;
		margin: 0 auto;
		background: none;
		position: absolute;
		color: #cf086e;
		top: 14px;
		right: 14px;
		cursor: pointer;
	}

	&__input {
		display: flex;
		width: 100%;
		height: 48px;
		box-sizing: border-box;
		align-items: center;
		font-size: 16px;
		line-height: 24px;
		outline: none;
		border: 1px solid rgba(1, 3, 12, 0.08) !important;

		& div {
			padding: 0 40px 0 20px;
			width: 100%;
			font-family: SourceSansPro, sans-serif !important;

			&:before {
				content: none !important;
			}

			&:after {
				content: none !important;
			}
		}

		&::placeholder {
			color: #aeaeb1;
		}
	}
}
