.Nav {
  min-width: 280px;
  width: 280px;
  padding-top: 5px;
  padding-right: 14px;
  border-right: 1px solid rgba(1,3,12,0.06);

  &__Container {
    display: flex;
    flex-direction: column;
    margin-top: 4px;
    margin-bottom: 32px;

    &__title {
      font-size: 12px;
      line-height: 20px;
      color: #01030C;
      opacity: 0.56;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__Link {
    display: flex;
    align-items: center;
    margin-top: 12px;
    cursor: pointer;
    font-size: 18px;
    line-height: 26px;
    transition: 0.3s;

    &_selected, &:hover {
      color: #CF086E;
    }

    img {
      margin-left: 8px;
    }
  }

  @media screen and (max-width: 1200px) {
    min-width: 186px;
    width: 186px;
  }
}