
.Breadcrumbs {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: -26px;
  margin-bottom: 24px;

  @media screen and (max-width: 768px) {
    margin-top: 0;
  }
}

.Arrow {
  margin: 0 12px;
}

.Link {
  color: rgba(1,3,12,0.56);
  cursor: pointer;
}