

.PopUp {
  border: none;
}

.Container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 486px;
  max-width: 90vw;
  padding: 30px 16px 50px 16px;
  background: #fff;
  border-radius: 12px;
  outline: none !important;
}

.Title {
  margin-bottom: 16px;
  text-align: center;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
}

.Description {
  margin-bottom: 32px;
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.Button {
  width: 390px;
  max-width: 100%;
  margin: 0 auto;
  cursor: pointer;
}

.CloseIcon {
  position: absolute;
  top: 18px;
  right: 18px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    opacity: 0.6;
  }
}