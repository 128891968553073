.ErrorPopup {
	margin-top: -2rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding-top: 11px;
	padding-left: 16px;
	padding-bottom: 11px;
	background: rgba(235, 87, 87, 0.08);
	border: 1px solid rgba(235, 87, 87, 0.24);
	box-sizing: border-box;

	@media screen and (max-width: 768px) {
		margin: 0;
	}

	&__text {
		font-size: 14px;
		line-height: 22px;
		color: #d53e3e;
	}

	&__close {
		cursor: pointer;
		margin-right: 16px;
		width: 12px;
		height: 12px;
	}
}
