.Title {
  margin-bottom: 32px;
  text-align: start;
}

.SubTitle {
  margin-bottom: 20px;
  text-align: start;
}

.ListItem {
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
}

p {
  white-space: pre-wrap;
  margin-bottom: 33px;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  &:last-child {
    margin-bottom: 0;
  }
}

.ContentBlock {
  margin-bottom: 50px;

  &:last-child {
    margin-bottom: 0;
  }
}

.Link {
  text-decoration: underline;
}