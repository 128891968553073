.ReactSelect {
  &-control {
    border: none !important;
  }
}

.Select {
  position: relative;
  width: 100%;
  height: 48px;
  background: #fff;
  border: 1px solid rgba(1,3,12,0.12);
  overflow: hidden;

  &_opened {
    height: auto;
    box-shadow: 0 0 5px rgba(0,0,0,0.5);

    .Items {
      margin: 0;
      max-height: 330px;
      height: auto;
      padding-bottom: 10px;
      overflow-y: auto;
      opacity: 1;
      transition: 0.3s;
    }

    .Item {
      transition: 0.3s;
      &:hover {
        color: #CF086E;
      }
    }

    .SelectedItem {
      border-bottom: 1px solid rgba(1,3,12,0.12);
    }
  }
}

.SelectedItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  padding: 0 16px;
  cursor: pointer;

  .SelectedValue, .Arrow path {
    transition: 0.3s;
  }

  &:hover {
    .SelectedValue {
      color: #CF086E;
      opacity: 1;
    }

    .Arrow path {
      fill: #CF086E;
    }
  }
}

.Items {
  height: 0;
  margin: 12px 0;
  z-index: 1;
  opacity: 0;
}

.Item {
  margin-top: 8px;
  padding: 0 16px;
  font-size: 16px;
  line-height: 1.5;
  cursor: pointer;
}