.MobileContent {
  width: 100%;

  &__Title {
    margin: 12px 0 4px;
    color: rgba(1,3,12,0.56);
    font-size: 12px;
    line-height: 20px;
  }

  &__Link {
    display: block;
    margin-bottom: 8px;
    font-size: 16px;
    line-height: 24px;
  }

  &__Container {
    margin-bottom: 32px;

    &:last-of-type {
      padding-bottom: 100px;
    }
  }

  &__SignIn {
    width: 100%;
    padding: 14px 0;
    border-bottom: 1px solid rgba(1,3,12,0.06);

    &Link {
      display: flex;
      align-items: center;
    }

    &Text {
      margin-left: 12px;
      font-weight: 600;
    }
  }
}
