.Container {
  width: 100%;
}

.TopRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;

  @media screen and (max-width: 576px) {
    margin-bottom: 24px;
  }
}

.Items {
  width: 100%;

  @media screen and (max-width: 1200px) {
    width: calc(100% + 20px);
  }

  @media screen and (max-width: 576px) {
    display: grid;
    flex-wrap: wrap;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
    width: 100%;
    margin: 0 auto;
  }
}

.Title {
  font-family: Cormorant Garamond !important;
  font-weight: 700;
  font-size: 36px;
  line-height: 1;

  @media screen and (max-width: 576px) {
    font-size: 24px;
  }
}

.Actions {
  display: flex;
}

.PrevArrow, .NextArrow {
  padding: 0;
}

.PrevArrow {
  right: 54px;

  @media screen and (max-width: 1200px) {
    right: 50px;
  }
}

.NextArrow {
  right: 12px;

  @media screen and (max-width: 1200px) {
    right: 10px;
  }
}

.Content {
  padding: 0 12px;

  @media screen and (max-width: 1200px) {
    padding: 0 10px;
  }
}

.ButtonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Link {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 0 48px;
  background: rgba(207, 8, 110, 0.06);
  border-radius: 100px;
  opacity: 1 !important;
  transition: 0.3s;
  white-space: nowrap;

  color: #cf086e;
  font-family: SourceSansPro !important;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5;

  &:hover {
    color: #fff;
    background: #cf086e;
  }
}
