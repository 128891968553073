
.NotFound {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 136px;
}

.Text {
  margin: 40px 0;
  white-space: break-spaces;
  text-align: center;
  font-size: 16px;
  line-height: 2;
}