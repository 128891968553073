@mixin mark {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 20px;
	padding: 0 10px;
	background: #cf086e;
	border-radius: 10px;
	color: #fff;
	font-weight: 600;
	font-size: 12px;
	line-height: 26px;
}

.Stickers {
	display: flex;
	flex-wrap: wrap;
}

.Container {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	grid-gap: 8px;
	line-height: 1.5;

	& > span:first-of-type {
		margin-right: 8px;
	}

	@media screen and (max-width: 768px) {
		margin-bottom: 8px;
	}
}

.CurrentPrice {
	margin-right: 8px;
}

.OldPrice {
	margin-right: 8px;
	color: rgba(1, 3, 12, 0.32);
	text-decoration-line: line-through;
}

.Discount {
	width: 43px;
	margin-right: 8px;
	@include mark;
}

.Last {
	margin-right: 8px;
	@include mark;
}
