
// TopRow

.TopRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;

  @media screen and (max-width: 576px) {
    flex-direction: column;
  }
}

.Title {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;

  @media screen and (max-width: 576px) {
    margin-bottom: 18px;
    text-align: center;
    font-size: 18px;
    line-height: 26px;
  }
}

.Date {
  width: 110px;
  text-align: center;
  margin: 0 12px;
  text-transform: capitalize;
}

.Manipulator {
  display: flex;
  align-items: center;
  user-select: none;
}

.Button {
  display: flex;
  width: 19px;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    opacity: 0.6;
  }

  &_reversed {
    transform: rotate(180deg);
  }
}

// Table

.Table {}

.Head, .Cells {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.Head {
  width: calc(100% + 2px);
  margin-left: -1px;
  border-top: 1px solid rgba(1, 3, 12, 0.06);
  border-left: 1px solid rgba(1, 3, 12, 0.06);
  border-right: 1px solid rgba(1, 3, 12, 0.06);
}

.Cells {
  grid-gap: 1px;

  .Cell {
    outline: 1px solid rgba(1, 3, 12, 0.06);
    transition: 0.3s;

    &:hover {
      cursor: pointer;
      opacity: 0.6;
    }
  }
}

.Head {
  .Cell {
    align-items: flex-end;
    height: 36px;
    font-size: 12px;
    line-height: 15px;
    color: #232A3E;
  }
}

.Cell {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  height: 83px;
  padding: 8px;
  user-select: none;

  &_selected {
    background: rgba(207,8,110,0.06);
    color: #CF086E;
  }

  @media screen and (max-width: 576px) {
    height: 61px;
  }
}

// Hours

.Hours {
  display: flex;
  flex-wrap: wrap;
  margin: 20px -4px -4px;

  @media screen and (max-width: 576px) {
    margin-top: 12px;
  }
}

.Hour {
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% / 7 - 8px);
  min-width: 70px;
  height: 40px;
  margin: 4px;
  border: 1px solid rgba(1, 3, 12, 0.08);
  border-radius: 100px;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    opacity: 0.6;
  }

  &_selected {
    background: rgba(207,8,110,0.06);
    color: #CF086E;
  }

  @media screen and (max-width: 576px) {
    width: calc(100% / 3 - 8px);
  }
}