
.Item {
  width: 100%;
  min-height: 42px;
  border-bottom: 1px solid rgba(1,3,12,0.06);
  transition: 0.5s;

  &_opened {
    .Content {
      height: auto;
      opacity: 1;
      transition: 0.5s;
    }
  }

  &_paddings {
    padding-bottom: 24px;

    .Content {
      padding: 8px 0 10px;
    }
  }

  &_space {
    margin-bottom: 20px;
  }

  &_compact {
    border: none;
    padding: 0;

    .TopPanel {
      justify-content: flex-start;
      padding: 3px 0;
    }
  }
}

.TopPanel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    opacity: 0.7;
  }
}

.Title {
  padding-right: 8px;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.5;
}

.Content {
  height: 1px;
  opacity: 0;
  overflow: hidden;
  font-size: 16px;
  line-height: 1.5;
  white-space: pre;
}

.Action {
  width: 19px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Icon {
  display: flex;
  margin-right: 8px;
}