
.Title {
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
}

.Text {
  color: rgba(1,3,12,0.56);
  font-size: 14px;
  line-height: 22px;
}

.Divider {
  margin: 16px 0;
}

.Phone {
  margin-bottom: 12px;
  color: rgba(207,8,110,0.8);
}

.Mail {
  margin-bottom: 20px;

  a {
    color: rgba(207,8,110,0.8);
  }
}

.Button {
  width: 100%;
}