
.Title {
  margin-bottom: 12px;
  text-align: center;
  font-family: Cormorant Garamond !important;
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;

  @media screen and (max-width: 768px) {
    font-size: 32px;
    line-height: 1.3;
    margin-bottom: 20px;
  }
}

.SubTitle {
  margin-bottom: 32px;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
}