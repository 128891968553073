.ForgotPassword {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  // justify-content: center;
}

.Email {
  width: 100%;
  max-width: 384px;
  margin: 30px auto 0;
}

.Title {
  margin-bottom: 20px;
  text-align: center;
  font-family: Cormorant Garamond !important;
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;

  @media screen and (max-width: 576px) {
    margin-bottom: 8px;
    font-size: 36px;
    line-height: 44px;
  }
}

.Message {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Link {
  color: #CF086E;
  margin: 0 auto 30px;
}

.SubTitle {
  margin-bottom: 36px;
  text-align: center;
  color: rgba(1,3,12,0.56);
  font-size: 16px;
  line-height: 24px;
}