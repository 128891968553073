
.Title {
  margin-bottom: 16px;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
}

.Item {
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }
}

.Heading {
  margin-bottom: 4px;
  font-size: 14px;
  line-height: 22px;
  color: rgba(207,8,110,0.56);
}

.Text {
  font-size: 18px;
  line-height: 26px;
}