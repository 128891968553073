.Button {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 40px;
	padding: 0 48px;
	background: rgba(207, 8, 110, 0.06);
	border-radius: 100px;
	opacity: 1 !important;
	white-space: nowrap;

	.Content {
		display: flex;
		align-items: baseline;
		justify-content: center;
		color: #cf086e;
		font-family: SourceSansPro !important;
		font-weight: 500;
		font-size: 16px;
		line-height: 1.5;
	}

	&:disabled {
		cursor: not-allowed;

		.Content {
			color: #fff !important;
		}
	}

	&:disabled .Content {
		color: rgba(1, 3, 12, 0.36) !important;
	}

	&:hover {
		&:not(:disabled) {
			background: #cf086e;

			.Content {
				color: #fff !important;
			}
		}
	}

	&:focus {
		background: #ab0c5e;

		.Content {
			color: #fff;
		}
	}

	&_filled {
		.Content {
			color: #fff !important;
		}

		&:not(:disabled) {
			height: 48px;
			background: #cf086e;

			&:hover,
			&:focus {
				color: #fff !important;
				background: #ab0c5e !important;

				.Content {
					color: #fff !important;
				}
			}
		}
	}

	&_inline {
		background: transparent !important;
		padding: 0;

		&:hover {
			color: #cf086e;

			path {
				fill: #cf086e;
			}
		}
	}

	&_white {
		background: transparent !important;
		border: 1px solid rgba(255, 255, 255, 0.24);

		.Content {
			color: #fff;
		}

		&:hover {
			opacity: 0.6;
		}
	}
}