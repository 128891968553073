
.Basket {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 64px;

  &__link {
    display: flex;
    border-radius: 100px;
    padding: 14px 48px;
    justify-content: center;
    font-size: 18px;
    line-height: 28px;
  }

  @media screen and (max-width: 1300px) {
    flex-direction: column-reverse;
  }
}

.LeftCol {
  width: calc(100% - 384px - 24px);

  &_fullWidth {
    width: 100%;
  }

  @media screen and (max-width: 1300px) {
    width: 100%;
  }
}

.RightCol {
  width: 384px;
  padding: 24px;
  border: 1px solid rgba(1, 3, 12, 0.06);

  @media screen and (max-width: 1300px) {
    width: 100%;
    margin-bottom: 23px;
    padding: 14px;
  }
}

.Total {
  display: flex;
  flex-direction: column;
  margin-bottom: 26px;

  &__Text {
    min-width: max-content;
    padding-right: 4px;
    color: rgba(1, 3, 12, 0.64);
    font-size: 16px;
    line-height: 1;
  }

  &__Border {
    width: 100%;
    height: 1px;
    margin: 15px 0;
    border-bottom: 1px dashed rgba(1, 3, 12, 0.24);
  }

  &__Price {
    min-width: max-content;
    margin-bottom: -2px;
    font-weight: 600;
    font-size: 24px;
    line-height: 1;
  }

  &__OldPrice {
    margin-left: 0;
    margin-right: 10px;
    color: rgba(1, 3, 12, 0.48);
    text-decoration-line: line-through;
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
  }
}

.PromoCodeInput {
  margin-bottom: 20px;
}

.Empty {
  width: 100%;
  height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  opacity: 0.6;
}

.StockBlocker {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  font-size: 12px;
  color: rgba(1, 3, 12, 0.48);
  text-align: center;
}