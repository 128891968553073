.BasketItem {
	min-height: 127px;
	padding: 24px 0;
	border-top: 1px solid rgba(1, 3, 12, 0.06);

	&:last-child {
		border-bottom: 1px solid rgba(1, 3, 12, 0.06);
	}

	@media screen and (max-width: 768px) {
		padding: 20px 0;
	}

	&_notInStock {
		.Preview, .Specifications {
			opacity: 0.5 !important;
		}

		.Remove, .Wrap, .Actions, .Row, .Card {
			opacity: 1 !important;
		}
	}

	&_centered {
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.Card {
	display: flex;
	align-items: center;
}

.Preview {
	min-width: 78px;
	width: 78px;
	height: 78px;
	margin-right: 25px;

	img {
		width: 100%;
		height: 100%;
		border-radius: 4px;
		object-fit: cover;
	}

	@media screen and (max-width: 768px) {
		min-width: 60px;
		width: 60px;
		height: 60px;
		margin-right: 16px;
	}
}

.Row {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: calc(100% - 78px - 25px);

	@media screen and (max-width: 768px) {
		width: calc(100% - 60px - 16px);
	}
}

.Specifications,
.Actions {
	width: 45%;

	@media screen and (max-width: 768px) {
		width: 100%;
	}
}

.Actions {
	display: flex;
	align-items: center;
	justify-content: flex-end;

	@media screen and (max-width: 768px) {
		margin-top: 15px;
		padding-left: 76px;
		justify-content: space-between;
	}
}

.Price {
	margin-right: 24px;
	font-size: 20px;
	line-height: 28px;
}

.Remove {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 30px;
	height: 30px;
	margin: 10px;
	transition: 0.3s;

	&:hover {
		cursor: pointer;
		opacity: 0.6 !important;
	}

	&_withAlert {
		border: 1px solid #cf086e;
		border-radius: 50%;
	}

	&__Alert {
		position: absolute;
		top: -7px;
		right: -5px;
		width: 15px;
		height: 15px;
		opacity: 1 !important;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #fff;
		font-size: 14px;
		font-weight: bold;
		border-radius: 50%;
		background: #cf086e;
	}
}

.Category {
	color: rgba(1, 3, 12, 0.56);
	font-size: 12px;
	line-height: 20px;
}

.NotAvailable {
	color: #d43e3e;
	font-size: 12px;
	line-height: 20px;
}

.Name {
	font-size: 18px;
	line-height: 26px;
}

.Info {
	display: flex;
	margin-top: 32px;
	margin-bottom: 24px;
	max-width: 450px;
	color: rgba(1, 3, 12, 0.64);
	font-size: 16px;
	line-height: 24px;

	&__Col {
		margin-right: 32px;

		&:last-child {
			margin-right: 0;
		}
	}

	&__Title {
		font-size: 14px;
		line-height: 22px;
		color: rgba(1, 3, 12, 0.56);
	}

	&__Value {
		font-size: 18px;
		line-height: 26px;
	}

	@media screen and (max-width: 768px) {
		margin: 15px 0 0;
	}
}
