
.RowSelector {
  display: flex;
  width: 100%;
  height: 48px;
}

.Item {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(1,3,12,0.12);
  margin-left: -1px;
  cursor: pointer;
  transition: 0.3s;
  font-weight: 600;
  font-size: 16px;

  &:hover, &_selected {
    color: #CF086E;
    background: rgba(207, 8, 110, 0.06);
    border: 1px solid rgba(207, 8, 110, 0.12);
  }
}