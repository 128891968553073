
.Row {
  margin-bottom: -1px;
}

.Content {
  padding: 19px 0;
}

.Title {
  margin-bottom: 16px;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
}

.Values, .Content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Type, .Price {
  font-size: 18px;
  line-height: 26px;
}

.Content {
  @media screen and (max-width: 576px) {
    align-items: flex-start;
  }
}

.Values {
  @media screen and (max-width: 576px) {
    flex-direction: column;
    align-items: flex-end;
  }
}

.Price {
  margin-right: 24px;

  @media screen and (max-width: 576px) {
    margin-right: 0;
    margin-bottom: 16px;
  }
}

.BottomPanel {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;

  @media screen and (max-width: 576px) {
    flex-direction: column;
  }
}

.Total {
  @media screen and (max-width: 576px) {
    margin-bottom: 16px;
  }
}

.Heading {
  color: rgba(1,3,12,0.56);
  font-size: 14px;
  line-height: 22px;
}

.Sum {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
}