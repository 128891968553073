
.Profile {
  width: 384px;
  max-width: 100%;
  margin: 0 auto;
}

.Action {
  cursor: pointer;
  color: #CF086E;
  transition: 0.3s;

  &:hover {
    opacity: 0.6;
  }
}

.Logout {
  background: transparent;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #01030C;
  opacity: 0.64;
  width: 100%;
  margin-top: 40px;
}
