
.Tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: -8px;
}

.Tag {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  margin-top: 8px;
  margin-right: 8px;
  padding: 0 16px;
  border: 1px solid rgba(1,3,12,0.08);
  border-radius: 100px;
  font-size: 12px;
  letter-spacing: 0.03em;
  cursor: pointer;
  transition: 0.3s;
  text-transform: uppercase;

  a {
    color: #01030C;
    opacity: 1 !important;
  }

  &:hover {
    background: rgba(207,8,110,0.06);

    a {
      color: #CF086E;
    }
  }

  &:focus {
    background: rgba(207,8,110,0.12);

    a {
      color: #CF086E;
    }
  }
}