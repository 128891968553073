.Title {
  margin-bottom: 32px;
  font-family: Cormorant Garamond !important;
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;

  @media screen and (max-width: 1200px) {
    font-size: 36px;
    line-height: 44px;
  }
}

.Heading {
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #e71d85;

  @media screen and (max-width: 1200px) {
    font-size: 20px;
    line-height: 28px;
  }
}

.Link {
  text-decoration: none;
  border-bottom: 1px solid #e71d85;
  color: #e71d85;
}

p {
  white-space: pre-wrap;
  margin-bottom: 33px;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: justify;
}

.Banner {
  display: flex;
  padding: 33px 0 80px;
  width: 100%;
  min-height: calc(100vh - 89px);
  background: #F5F5F5;
  margin-bottom: -1px;

  &_show {
    opacity: 1;
  }

  @media screen and (max-width: 1200px) {
    padding: 20px 16px 16px;
  }

  @media screen and (max-width: 768px) {
    min-height: calc(100vh - 68px);
  }
}

.Wrapper {
  position: relative;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 96px;
  background: #fff;
  border-radius: 12px;

  @media screen and (max-width: 576px) {
    padding-bottom: 30px;
  }
}

.Content {
  padding: 32px 100px;
  width: 100%;
  margin: 0 auto 0;

  img {
    position: absolute;
    right: 32px;
    top: 32px;
    cursor: pointer;
  }

  @media screen and (max-width: 1200px) {
    padding: 32px 100px;
  }

  @media screen and (max-width: 768px) {
    padding: 32px 30px;
  }

  @media screen and (max-width: 576px) {
    padding: 32px 16px;
  }
}
