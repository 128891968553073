
.Checkbox {
  path {
    color: #CF086E !important;
  }
}

.Label {
  font-size: 14px;
  line-height: 22px;
}

.Error {
  font-size: 12px;
  line-height: 16px;
  color: #D53E3E;
}
