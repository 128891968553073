.Contacts {
  margin-bottom: 24px;

  &__Cols {
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 576px) {
      flex-direction: column;
    }
  }

  &__Col {
    width: calc(100% / 2 - 12px);

    &:nth-child(2n) {
      width: 50% !important;

      & > div {
        width: 100% !important;

        input {
          padding: 12px 0 10px !important;
        }
      }
    }

    @media screen and (max-width: 576px) {
      width: 100%;
    }
  }
}
