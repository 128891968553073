
.HeaderCatalog {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: calc(100vh - 89px);
  padding-bottom: 50px;
  overflow-y: scroll;
  background: #fff;
  z-index: 2;

  @media screen and (max-width: 576px) {
    height: calc(100vh - 68px);
  }
}

.Wrapper {
  display: flex;
  width: 1200px;
  max-width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 16px;

  @media screen and (max-width: 1200px) {
    width: 768px;
  }
}
