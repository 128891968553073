
.RemindPassword {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
}

.Title {
  margin-bottom: 20px;
  text-align: center;
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;

  @media screen and (max-width: 576px) {
    margin-bottom: 8px;
    font-size: 36px;
    line-height: 44px;
  }
}

.Input {
  margin-bottom: 40px !important;
}

.SubTitle {
  max-width: 300px;
  margin: 0 auto 40px;
  text-align: center;
  color: rgba(1,3,12,0.56);
  font-size: 16px;
  line-height: 24px;
}

.Content {
  width: 384px;
  max-width: 100%;
  margin: 0 auto;
}