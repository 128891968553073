
.Row {
  display: flex;
  margin-bottom: 64px;

  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }
}

.SpeacialOrderContainer {
  display: flex;
  justify-content: center;
}

.SpeacialOrderContent {
  max-width: 384px;
  width: 100%;
  display: flex;
  flex-direction: column;
  // align-items: center;
}

.SpeacialOrderTitle {
  margin-bottom: 12px;
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;
  text-align: center;
}

.SpeacialOrderDescription {
  margin-bottom: 34px;
  max-width: 384px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  opacity: 0.56;
  text-align: center;
}

.SpeacialOrderInput {
  &:last-child {
    margin-bottom: 0;
  }
}

.SpeacialOrderSubmit {
  margin-top: 39px;
}

.Slider {
  position: relative;
  max-width: 642px;
  width: 100%;

  &__PrevArrow, &__NextArrow {
    top: 35%;
    width: 28px;
    height: 32px;
    padding: 0;

    @media screen and (max-width: 1200px) {
      top: 40%;
    }

    @media screen and (max-width: 768px) {
      top: 45%;
    }
  }

  &__PrevArrow {
    left: 20px;

    @media screen and (max-width: 768px) {
      left: 10px;
    }
  }

  &__NextArrow {
    right: 20px;

    @media screen and (max-width: 768px) {
      right: 10px;
    }
  }

  &__Selected, &__Slide {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(1,3,12,0.06);

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;

      @media screen and (max-width: 991px) {
        object-fit: contain;
      }
    }
  }

  &__Selected {
    position: relative;
    height: 600px;
    border: 1px solid rgba(1,3,12,0.06);

    @media screen and (max-width: 1200px) {
      height: 450px;
    }

    @media screen and (max-width: 768px) {
      height: 300px;
    }
  }

  &__Nav {
    position: absolute;
    top: calc(50% - 16px);
    left: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &__Row {
    display: grid;
    align-items: center;
    grid-auto-flow: column;
    overflow-x: auto;
    grid-auto-columns: calc((100% / 6) - 7px);
    grid-template-columns: unset;
    grid-template-rows: unset;
    grid-gap: 8px;
    margin-top: 8px;

    @media screen and (max-width: 1200px) {
      grid-auto-columns: calc(25% - 6px);
    }
  }

  &__Slide {
    width: 100%;
    height: 100px;
    margin-right: 8px;
    margin-bottom: 8px;
    padding: 8px;
    cursor: pointer;
    transition: 0.3s;

    &_selected {
      border-color: rgba(207, 8, 110, 0.5);
      opacity: 1 !important;
    }

    &:hover {
      opacity: 0.6;
    }

    &:last-child {
      margin-right: 0;
    }

    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }

  @media screen and (max-width: 1200px) {
    width: 100%;
    margin-bottom: 40px;
    padding-left: 0;
  }

  @media screen and (max-width: 768px) {
    margin-bottom: 20px;
  }
}

.Product {
  width: 50%;
  padding-left: 72px;

  @media screen and (max-width: 1200px) {
    width: 486px;
    max-width: 100%;
    padding-left: 0;
  }
}

.Title {
  margin-bottom: 20px;
  font-family: Cormorant Garamond !important;
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;

  @media screen and (max-width: 768px) {
    margin-bottom: 12px;
    font-size: 24px;
    line-height: 32px;
  }
}

.Price {
  margin-bottom: 28px;
  font-size: 24px;
  line-height: 32px;

  @media screen and (max-width: 768px) {
    margin-bottom: 20px;
  }
}

.Select {
  width: 100%;
  height: 48px;
  margin-bottom: 12px;
}

.Link {
  width: max-content;
  margin-bottom: 32px;
  font-size: 14px;
  line-height: 22px;

  a {
    color: #CF086E;
  }
}

.Actions {
  display: flex;
  align-items: center;
  margin-bottom: 32px;

  button, a {
    height: 48px !important
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
    grid-gap: 10px;
  }
}

.SpecialOrder {
  margin-left: 10px;

  @media screen and (max-width: 768px) {
    margin-left: 0;
  }
}

.Like {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  margin-left: 16px;
  background: #fff;
  border: 1px solid rgba(1,3,12,0.08);
  border-radius: 50%;

  &Tablet {
    background: #fff;
    border: 1px solid rgba(1,3,12,0.08);

    span {
      color: black !important;
    }

    &__Heart {
      display: flex;
      margin-right: 16px;
    }

    &__Content {
      align-items: center !important;
    }
  }
}

.Stats {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -20px;
}

.Description {
  word-wrap: break-word;
  white-space: break-spaces;
}

.Dropdown {
  margin-bottom: 32px;
}

.ColorPicker {
  margin-bottom: 36px;
}

.CartButtonLoading {
  min-width: 230px;
  width: max-content;
}