.Title,
.SubTitle {
	text-align: center;
}

.LogoErmitag {
	position: absolute;
	bottom: 386px;
	right: 0;

	@media screen and (max-width: 1200px) {
		display: none;
	}
}

.Title {
	margin-bottom: 12px;
	font-family: Cormorant Garamond !important;
	font-weight: 700;
	font-size: 48px;
	line-height: 56px;

	@media screen and (max-width: 576px) {
		margin-bottom: 8px;
		font-size: 36px;
		line-height: 44px;
	}
}

.SubTitle {
	margin-bottom: 24px;
	color: rgba(1, 3, 12, 0.56);
	font-size: 16px;
	line-height: 24px;
}

.Button {
	width: 100%;
	margin-top: 32px;

	@media screen and (max-width: 576px) {
		margin-top: 24px;
	}
}

.SocialLinks {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	align-items: center;
	justify-content: space-between;
	margin-top: 15px;
	margin-bottom: 25px;
	grid-gap: 0 5px;
}

.SocialLink {
	padding: 7px 0;
	display: flex;
	align-items: center;
	justify-content: center;
	// width: 40px;
	height: 45px;
	// border-radius: 25%;
	cursor: pointer;
	border: 1px solid #f0f0f0;
	transition: 0.3s;

	&:hover {
		opacity: 0.7;
	}

	svg {
		width: 100%;
		height: 100%;
	}
}

.Link {
	width: max-content;
	margin-top: -12px;
	margin-bottom: 10px;
	color: #CF086E;
	font-size: 14px;
	line-height: 22px;
	transition: 0.3s;

	&:hover {
		cursor: pointer;
		opacity: 0.6;
	}
}

.Container {
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 300px;
}