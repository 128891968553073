.Offer {
  margin-top: 16px;

  &Actions {
    display: flex;
    flex-wrap: wrap;
    margin-top: 26px;

    @media screen and (max-width: 768px) {
      margin-top: 20px;

      .Button {
        padding: 0 20px;
      }
    }
  }
}

.Category {
  color: rgba(1,3,12,0.56);
  font-size: 12px;
  line-height: 20px;
}

.Name {
  font-size: 18px;
  line-height: 26px;
}

.Info {
  display: flex;
  margin-top: 32px;
  margin-bottom: 24px;
  max-width: 450px;
  color: rgba(1,3,12,0.64);
  font-size: 16px;
  line-height: 24px;

  &__Col {
    margin-right: 32px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__Title {
    font-size: 14px;
    line-height: 22px;
    color: rgba(1,3,12,0.56);
  }

  &__Value {
    font-size: 18px;
    line-height: 26px;
  }

  @media screen and (max-width: 768px) {
    margin: 15px 0 0;
  }
}

.Prices {
  display: flex;
  align-items: center;

  .Price {
    margin-right: 8px;
  }

  @media screen and (max-width: 768px) {
    margin-top: 12px;
  }
}

.OldPrice {
  font-size: 18px;
  line-height: 26px;
  text-decoration-line: line-through;
  color: rgba(1, 3, 12, 0.32);
}

.Discount {
  margin-left: 12px;
  padding: 3px 6px;
  background: #CF086E;
  border-radius: 10px;
  color: #fff;
  font-weight: 600;
  font-size: 12px;
}

.Button {
  height: 40px;
  margin-right: 12px;

  @media screen and (max-width: 768px) {
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
