
.OrderItem {
  width: 100%;
  // max-width: 792px;
  margin: 0 auto;
}

.Order {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;
}

.Info {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: calc(100% - 78px - 25px);

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.Category, .Id {
  margin-bottom: 4px;
  font-size: 12px;
  line-height: 16px;
  color: rgba(1,3,12,0.56);
}

.Id {
  margin-left: 12px;
}

.Name {
  font-size: 18px;
  line-height: 26px;
  max-width: 700px;
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Preview {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 78px;
  height: 78px;
  border: 1px solid rgba(1,3,12,0.06);
  padding: 5px;
}

.Row {
  display: flex;
  align-items: center;
}

.Status {
  display: flex;
  align-items: center;
  width: 100px;

  &__Circle {
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }

  &__Name {
    margin-left: 8px;
  }

  @media screen and (max-width: 460px) {
    margin-top: 6px;
  }
}