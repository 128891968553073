
.Arrow {
  position: absolute;
  top: -66px;
  // right: 72px;
  right: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 32px;
  background: none;

  path {
    fill: #01030C;
  }

  &_right {
    // right: 24px;
    right: 0;
    transform: rotate(180deg);
  }

  &_disabled {
    opacity: 0.2 !important;
    cursor: default;
  }
}