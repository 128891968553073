
.TopPanel {
  display: flex;
  align-items: center;
}

.Date, .Price {
  font-weight: 400;
  font-size: 18px;
  line-height: 1.5;
  color: #01030C;
}

.Price {
  min-width: 85px;
  margin: 0 32px;
}

.Status {
  display: flex;
  align-items: center;
  width: 100px;

  &__Circle {
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }

  &__Name {
    margin-left: 8px;
    opacity: 0.48;
  }

  @media screen and (max-width: 460px) {
    margin-top: 6px;
  }
}