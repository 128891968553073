
.RadioGroup {
  margin-bottom: 20px;

  path {
    color: #CF086E !important;
  }
}

.Label {
  color: rgba(1,3,12,0.48);
  font-size: 12px;
  line-height: 20px;
}

.Items {
  display: flex;
  flex-direction: row !important;
}

.Error {
  font-size: 12px;
  line-height: 16px;
  color: #D53E3E;
}
