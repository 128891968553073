.ForgotPassword {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
}

.Input {
  width: 420px;
  max-width: 100%;
  margin: 30px auto 0;
}

.Title {
  margin: 0 auto 20px;
  text-align: center;
  font-family: Cormorant Garamond !important;
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;

  @media screen and (max-width: 576px) {
    margin-bottom: 8px;
    font-size: 36px;
    line-height: 44px;
  }
}

.Link {
  color: #CF086E;
  margin: 0 auto 30px;
}

.Message {
  display: flex;
  align-items: center;
  justify-content: center;

  &.Error {
    color: #F44336;
  }
}