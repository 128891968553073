.SuccesssPopup {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: center;
}

.SuccesssPopup__title {
      font-weight: 700;
      font-size: 40px;
      line-height: 56px;
      color: #01030C;
      font-family: 'SourceSansPro', sans-serif;
      text-align: center;
      font-style: normal;
      padding-top: 32px;
}
  
.SuccesssPopup__subtitle {
      max-width: 384px;
      width: 100%;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #01030C;
      opacity: 0.56;
      padding-top: 12px;
}

.SuccesssPopup__button {
      margin-top: 32px;
}