
.Filters {
  // padding: 20px;
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  width: 370px;
  height: 100vh;
  background: #fff;
  z-index: 100;
}

.Wrapper {
  margin: 0 auto;
  height: 100%;
}

.Close {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;

  @media screen and (max-width: 703px) {
  }
}

.Title {
  display: inline-block;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;

  @media screen and (max-width: 703px) {
    margin-top: 15px;
  }
}

.ThrowOff {
  margin-left: 12px;
  cursor: pointer;
  color: #CF086E;
  font-size: 14px;
  line-height: 22px;
  transition: 0.3s;

  &:hover {
    opacity: 0.6;
  }
}

.Header {
  padding: 20px 20px 0 20px;
}

.Selects {
  margin: 24px 0;
  padding: 0 20px 100px 20px;
  height: 100%;
  overflow: auto;
}

.Select {
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
}

.Button {
  margin-top: 32px;
  width: 100%;
}

.Heading {
  margin-bottom: 12px;
  color: rgba(1,3,12,0.56);
  font-size: 12px;
  line-height: 20px;
}

.Item {
  margin-bottom: 28px;
}
