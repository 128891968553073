.PaymentType {
  &__Systems {
    display: flex;
  }

  &__System {
    display: flex;
    align-items: center;
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__Radio {
    display: flex;
    align-items: center;
    justify-content: space-between !important;

    &Items {
      flex-direction: column !important;
    }

    &Item {
      height: 56px;
      border-top: 1px solid rgba(1,3,12,0.06);

      &:last-child {
        border-bottom: 1px solid rgba(1,3,12,0.06);
      }

      span:last-child {
        width: 100%;
      }
    }
  }
}

.Title {
  margin: 24px 0;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
}
