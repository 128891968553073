
.OrderAccepted {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 500px;
  max-width: 100%;
  margin: 0 auto;
  padding: 63px 10px;
}

.Title, .SubTitle, {
  width: 100%;
  text-align: center;
  white-space: pre-line;
}

.Title {
  margin-bottom: 12px;
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;
}

.SubTitle {
  margin-bottom: 33px;
  color: rgba(1,3,12,0.56);
  font-size: 16px;
  line-height: 24px;
}

.Wrapper {
  width: 384px;
  max-width: 100%;
}

.Review {
  padding: 15px 25px 25px;
  border: 1px solid rgba(1,3,12,0.06);
}

.Grade {
  margin-bottom: 4px;
  color: rgba(1,3,12,0.48);
  font-size: 12px;
  line-height: 20px;
}

.Rating {
  margin-bottom: 20px;
}

.Container {
  margin-top: 40px;
}
