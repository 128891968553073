.Title {
    margin-bottom: 32px;
    text-align: start;
}

li {
    margin-bottom: 10px;

    &:last-child {
        margin-bottom: 0;
    }
}