
.Items {
  display: grid;
  flex-wrap: wrap;
  margin-bottom: 42px;
}

.Item {
  width: 100%;
}

.Button {
  display: flex;
  justify-content: center;
}
