
.Block {
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }
}

.Title {
  margin-bottom: 4px;
  font-size: 14px;
  line-height: 22px;
  opacity: 0.56;
}

.Text {
  display: flex;
  align-items: baseline;
  font-size: 18px;
  line-height: 26px;
}

.ShopInfo {
  display: flex;
  justify-content: space-between;
  padding-top: 30px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}

.List {
  width: 42.5%;
  padding-right: 24px;

  @media screen and (max-width: 768px) {
    width: 100%;
    margin-bottom: 30px;
    padding-right: 0;
  }
}

.MarkUpContent {
  margin: 10px 10px 15px;
}

.Map {
  width: 57.5%;
  height: 600px;
  cursor: pointer;
  border-radius: 16px;

  &__Ymap {
    width: 100%;
    height: 90%;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    margin-bottom: 30px;
  }
}

.Name {
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.5;
}