.CategoryCard {
	display: flex;
	align-items: center;
	flex-direction: column;
	width: 120px;
	margin: 0 auto;
	cursor: pointer;

	&_selected {
		.Label {
			font-weight: 600;
			color: #CF086E;
			opacity: 1 !important;
		}

		.Icon {
			background: rgba(1, 3, 12, 0.04);
			border: none;
		}
	}

	.Icon {
		img {
			width: 90%;
			height: 90%;
			border-radius: inherit;
			transition: 0.3s;
		}
	}

	&:hover {
		.Icon {
			border: none;

			img {
				transform: scale(1.1);
			}
		}

		.Label {
			opacity: 0.6;
		}
	}

	@media screen and (max-width: 576px) {
		width: 90%;
	}
}

.Icon {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 114px;
	height: 114px;
	margin-bottom: 10px;
	border: 1px solid rgba(1, 3, 12, 0.08);
	border-radius: 12px;
	overflow: hidden;

	img {
		object-fit: cover;
		object-position: center;
	}
}

.Label {
	text-align: center;
	font-size: 14px;
	line-height: 24px;
	transition: 0.3s;
}
