.RowCategories {
	height: 188px;

	@media screen and (max-width: 768px) {
		height: 168px;
	}
}

.Back {
	display: flex;
	align-items: center;
	margin: 0 0 30px;
	color: #CF086E;
	cursor: pointer;
	transition: 0.3s;

	&__Icon {
		margin-right: 5px;
		transform: rotate(90deg);
		color: #CF086E;
	}

	&:hover {
		opacity: 0.7;
	}
}

.RowCategories__Card {
	height: 170px;
}

.RowCategories__CardLabel {
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}

.PrevArrow,
.NextArrow {
	top: 30%;
	width: 40px;
	height: 40px;

	@media screen and (max-width: 991px) {
		display: none;
	}
}

.PrevArrow {
	left: -64px;
}

.NextArrow {
	right: -64px;
}
