.Wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: none;
    justify-content: center;
    align-items: center;
    z-index: 100;
    overflow: hidden;
}
.Wrapper_open {
    display: flex;
}

.Card {
    padding: 28px;
    max-width: 1199px;
    width: 100%;
    background: #fff;
    border-radius: 12px;
    z-index: 101;
}

.CardHeader {
    margin-bottom: 16px;
    display: flex;
    justify-content: flex-end;
}

.CloseIcon {
    cursor: pointer;
}

.Background {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: #01030C;
    opacity: 0.48;
    z-index: 100;
}