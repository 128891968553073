
.RemindSuccess {
  margin-top: 70px;
}

.Title {
  margin-top: 110px;
  margin-bottom: 32px;
  text-align: center;
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;

  @media screen and (max-width: 576px) {
    margin-bottom: 24px;
    font-size: 32px;
    line-height: 38px;
  }
}

.SubTitle {
  margin-bottom: 32px;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
}

.Button {
  width: 235px;
  margin: 0 auto;

  @media screen and (max-width: 576px) {
    width: 100%;
    max-width: 235px;
  }
}