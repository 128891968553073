.Title {
  margin-bottom: 32px;
  font-family: Cormorant Garamond !important;
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;

  @media screen and (max-width: 1200px) {
    font-size: 36px;
    line-height: 44px;
  }
}

.Heading {
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #e71d85;

  @media screen and (max-width: 1200px) {
    font-size: 20px;
    line-height: 28px;
  }
}

.Link {
  text-decoration: none;
  border-bottom: 1px solid #e71d85;
  color: #e71d85;
}

p {
  white-space: pre-wrap;
  margin-bottom: 33px;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: justify;
}

.List {
  white-space: pre-wrap;
  margin-bottom: 33px;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: justify;
}
